<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Devices to be approved
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="material-card-overflow"
      internal-card="material-card material-card-content material-card-overflow"
      url-help=""
      style="overflow: visible"
    >
      <div
        slot="external-header"
        style="padding: 2%;"
      >
        <b-message
          type="is-primary"
        >
          <p class="center-vertically-action-button">
            <span>
              The following devices require your approval to access the company
            </span>
          </p>
        </b-message>
        <b-table
          ref="deviceListTable"
          :data="devicesToBeApprovedResult"
          :mobile-cards="false"
          :striped="true"
          :paginated="totalData > limitDevices"
          :per-page="limitDevices"
          :loading="devicesToBeApprovedResult.length === 0 && isFindDevicesToBeApprovedPending"
          :current-page.sync="currentPage"
          :show-detail-icon="false"
          :default-sort="sortField"
          :default-sort-direction="sortDirection"
          hoverable
          scrollable
          backend-pagination
          backend-sorting
          :total="totalData"
          @page-change="changePagination"
          @sort="sortTable"
        >
          <b-table-column>
            <template v-slot:header>
              <b-checkbox
                @input="(value) => onCheckAll(value)"
              />
            </template>
            <template v-slot="props">
              <b-checkbox
                :value="checkedRows.map((x)=> x.id).includes(props.row.id)"
                @input="(value) => onCheckRow(
                  value,props.row.id, props.row.name, props.row.userName)"
              />
            </template>
          </b-table-column>
          <b-table-column
            v-for="(column, index) in columns"
            :key="index"
            :label="column.label"
            :field="column.field"
            sortable
          >
            <template v-slot="props">
              {{ props.row[column.field] }}
            </template>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="file"
                    size="is-large"
                  />
                </p>
                <p>There are no devices for approval</p>
              </div>
            </section>
          </template>
        </b-table>
        <div
          style="display: flex; flex-wrap: wrap; gap:5px 5px; margin-block: 2%;
          justify-content: center;"
        >
          <b-button
            type="is-primary"
            :disabled="checkedRows.length < 1"
            @click="openConfirmationModal(approvalStatus.APPROVED.value)"
          >
            Approve
          </b-button>
          <b-button
            type="is-primary"
            :disabled="checkedRows.length < 1"
            @click="openConfirmationModal(approvalStatus.DOUBLE_BOOT.value)"
          >
            Approve with double boot
          </b-button>
          <b-button
            type="is-primary"
            :disabled="checkedRows.length < 1"
            @click="openConfirmationModal(approvalStatus.NOT_APPROVED.value)"
          >
            Reject
          </b-button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';

import card from '@/components/cross/Card.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import vtulEnums from '../../../cross/index';

const { approvalStatus } = vtulEnums.enum.device;
const emitToAgentEnum = vtulEnums.enum.emitEvent.emitToAgent;

export default {
  name: 'DevicesToBeApproved',
  components: {
    card,
  },
  mixins: [makeFindMixin({ service: 'devices-to-be-approved', watch: true })],
  data() {
    return {
      limitDevices: 20,
      sortDirection: 'asc',
      sortField: 'name',
      skipDevices: 0,
      currentPage: 1,
      checkedRows: [],
      approvalStatus,
      columns: [{
        field: 'name',
        label: 'Name',
      }, {
        field: 'statusName',
        label: 'Status',
      }, {
        field: 'hardwareSerial',
        label: 'Serial',
      }, {
        field: 'OS',
        label: 'OS',
      }, {
        field: 'osArch',
        label: 'Architecture',
      }, {
        field: 'model',
        label: 'Model',
      }, {
        field: 'publicIp',
        label: 'Public IP',
      }],
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    currentPaginationQuery() {
      return {
        $limit: this.limitDevices,
        $skip: this.skipDevices,
      };
    },
    queryForSearchDevicesToBeApproved() {
      const query = {
        $sort: { [this.sortField]: this.sortDirection === 'asc' ? [1, 1] : [-1, -1] },
        companyId: this.currentCompany.id,
      };
      return query;
    },
    devicesToBeApprovedParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchDevicesToBeApproved,
        },
      };
    },
    devicesToBeApprovedResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.devicesToBeApprovedLatestQuery || !this.devicesToBeApprovedPaginationData.default
          || !this.devicesToBeApprovedPaginationData
            .default[this.devicesToBeApprovedLatestQuery.queryId]
            || !this.devicesToBeApprovedPaginationData
              .default[this.devicesToBeApprovedLatestQuery.queryId][paginationId]) {
        return [];
      }

      const { queryId } = this.devicesToBeApprovedLatestQuery;
      let devicesIds = this.devicesToBeApprovedPaginationData
        .default[queryId][paginationId].ids;
      if (this.currentPage === 1) {
        // This is a trick to display the new devices reactive
        const filterDevices = this.devicesToBeApproved;
        devicesIds = filterDevices.map((x) => x.id).concat(devicesIds);
        devicesIds = [...new Set(devicesIds)];
      }

      const devices = this.findDevicesToBeApprovedInStore({
        query: {
          companyId: this.currentCompany.id,
          id: {
            $in: devicesIds,
          },
          $limit: this.limitDevices,
          $sort: { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 },
        },
      });

      const preparedDevices = [];
      devices.data.forEach((device) => {
        const currentDevice = device;
        const statusName = approvalStatus.enums.find((x) => device.status === x.value.dbValue)
          .value.name;
        currentDevice.statusName = statusName;
        preparedDevices.push(currentDevice);
      });

      return preparedDevices;
    },
    totalData() {
      if (!this.devicesToBeApprovedLatestQuery || !this.devicesToBeApprovedPaginationData.default
          || !this.devicesToBeApprovedPaginationData
            .default[this.devicesToBeApprovedLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.devicesToBeApprovedLatestQuery;
      const { total } = this.devicesToBeApprovedPaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitDevices, 10);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }
      return total;
    },
  },
  methods: {
    ...mapActions('emit-to-agent', { emitToAgent: 'find' }),
    ...mapActions('devices-to-be-approved', { patchDevices: 'patch' }),
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipDevices = (this.currentPage - 1) * this.limitDevices;
    },
    sortTable(field, direction) {
      this.sortField = field;
      this.sortDirection = direction;
    },
    onCheckRow(value, id, name, userName) {
      if (value) {
        this.checkedRows.push({ id, name, userName });
      } else {
        const index = this.checkedRows.findIndex((x) => x.id === id);
        if (index !== -1) {
          this.checkedRows.splice(index, 1);
        }
      }
    },
    onCheckAll(value) {
      if (value) {
        this.checkedRows = this.devicesToBeApprovedResult.map((x) => ({
          id: x.id,
          name: x.name,
          userName: x.userName,
        }));
      } else {
        this.checkedRows = [];
      }
    },
    async applySettings(status) {
      await this.patchDevices([null,
        { status: status.dbValue, companyId: this.currentCompany.id },
        {
          query: {
            id: { $in: this.checkedRows.map((x) => x.id) },
          },
        }]);
      this.emitToAgent({
        query: {
          type: emitToAgentEnum.EMIT_AUTHENTICATION_EVENT.value.id,
          userNames: this.checkedRows.map((x) => x.userName),
        },
      });
      this.checkedRows = [];
    },
    openConfirmationModal(status) {
      let names = '';
      this.checkedRows.forEach((row) => {
        names += `<li> - <b>${row.name}</b></li>`;
      });
      const message = `The following devices will be marked as ${status.name.toLowerCase()} and will be removed from the list when the device attempts to log in
        <br><br><ul>${names}</ul>
        <br>Are you sure you want to mark them as ${status.name.toLowerCase()}?`;
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          title: status.modalTitle,
          message,
          confirmText: 'Accept',
          onConfirm: () => this.applySettings(status),
        },
      });
    },
  },
};
</script>

<style>
.centerVertically > strong {
    margin-right: 4px;
}
.centerVertically > button {
    margin-left: 4px;
}
.center-vertically-action-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
